import ApplicationController from 'modules/application_controller';
import isObject from 'plugins/utilities/is_object';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import viewportWidth from 'plugins/utilities/viewport_width';

export default class extends ApplicationController {
  static get targets() {
    return ['content', 'text', 'item', 'button', 'revealText', 'hideText'];
  }

  initialize() {
    this.props = {
      visibleCount: 0,
      prevAlwaysVisibleCount: 0,
      alwaysVisibleCount: null,
      type: null,
      isRevealed: false,
      truncatedTextElement: null,
      originalTextElement: null,
    };
  }

  connect() {
    this.initType();
    this.initHidden();
  }

  disconnect() {
    this.destroy();
  }

  initHidden() {
    this.visibleCount = this.alwaysVisibleCount;

    if (this.isAllVisible) {
      this.buttonTarget.hidden = true;

      return;
    }

    if (this.isRevealed) {
      this.open(true);
      return;
    }

    this.hide(true);
  }

  initType() {
    this.type = this.targets.has('text') ? 'text' : 'items';

    if (this.type !== 'items') {
      this.initTextType();
    }
  }

  initTextType() {
    const originalContent = this.textTarget.innerHTML;
    const noHTMLContent = this.textTarget.textContent;

    if (this.isAllVisible) {
      return;
    }

    this.originalTextElement = document.createElement('span');
    this.originalTextElement.hidden = false;
    this.truncatedTextElement = document.createElement('span');
    this.truncatedTextElement.hidden = true;

    this.originalTextElement.innerHTML = originalContent;

    if (noHTMLContent.length === originalContent.length) {
      this.truncatedTextElement.innerHTML = `${originalContent.substring(0, this.alwaysVisibleCount).trim()}…`;
    } else {
      const substrings = originalContent.split(/(<[^>]+>)/g).filter(Boolean);
      const truncated = [];
      let count = 0;
      let isTagOpen = false;

      for (let i = 0; i < substrings.length; i++) {
        if (!isTagOpen && count > this.alwaysVisibleCount) {
          break;
        }

        const substr = substrings[i];
        const isTag = substr.match(/^<[^>]+>$/);

        if (isTag) {
          isTagOpen = !isTagOpen;
          truncated.push(substr);
        } else {
          if (count + substr.length <= this.alwaysVisibleCount) {
            truncated.push(substr);
          } else {
            const remaining = this.alwaysVisibleCount - count;
            const truncatedSubstr = `${substr.substring(0, remaining - 1)}…`;

            truncated.push(truncatedSubstr);
          }

          count += substr.length;
        }
      }

      this.truncatedTextElement.innerHTML = truncated.join('');
    }

    this.textTarget.innerHTML = '';
    this.textTarget.insertAdjacentElement('beforeend', this.originalTextElement);
    this.textTarget.insertAdjacentElement('beforeend', this.truncatedTextElement);
  }

  checkVisibleCount() {
    if (
      this.isRevealed ||
      this.prevAlwaysVisibleCount === this.alwaysVisibleCount ||
      this.visibleCount === this.alwaysVisibleCount
    ) {
      return;
    }

    this.destroy();
    this.initType();
    this.initHidden();
  }

  open(force = false) {
    if (!force && this.isRevealed) {
      return;
    }

    this.isRevealed = true;
    this.revealTextTarget.hidden = true;
    this.hideTextTarget.hidden = false;

    if (this.type === 'items') {
      this.openItems();
    } else if (this.type === 'text') {
      this.openText();
    }
  }

  hide(force = false) {
    if (!force && !this.isRevealed) {
      return;
    }

    this.isRevealed = false;
    this.revealTextTarget.hidden = false;
    this.hideTextTarget.hidden = true;

    if (this.type === 'items') {
      this.hideItems();
    } else if (this.type === 'text') {
      this.hideText();
    }
  }

  openItems() {
    this.itemTargets.forEach((item) => {
      item.hidden = false;
    });
    this.visibleCount = this.itemTargets.length;
  }

  hideItems() {
    this.visibleCount = this.alwaysVisibleCount;
    this.itemTargets.forEach((item, index) => {
      if (index >= this.alwaysVisibleCount) {
        item.hidden = true;
      }
    });
  }

  openText() {
    this.originalTextElement.hidden = false;
    this.truncatedTextElement.hidden = true;
    this.visibleCount = this.textTarget.textContent.length;
  }

  hideText() {
    this.visibleCount = this.alwaysVisibleCount;
    this.truncatedTextElement.hidden = false;
    this.originalTextElement.hidden = true;
  }

  toggle() {
    if (this.isRevealed) {
      this.hide();
      return;
    }

    this.open();
  }

  destroy() {
    if (!this.isAllVisible) {
      if (this.type === 'items') {
        this.openItems();
      } else {
        this.textTarget.innerHTML = this.originalTextElement.innerHTML;
      }
    }

    this.visibleCount = 0;
    this.prevAlwaysVisibleCount = 0;
    this.props.alwaysVisibleCount = null;
    this.type = null;
    this.isRevealed = false;
    this.truncatedTextElement = null;
    this.originalTextElement = null;
  }

  get alwaysVisibleCount() {
    if (this.props.alwaysVisibleCount === null) {
      this.props.alwaysVisibleCount = JSON.parse(this.element.dataset.alwaysVisible);
    }

    if (!isObject(this.props.alwaysVisibleCount)) {
      return this.props.alwaysVisibleCount;
    }

    const width = viewportWidth();
    let result = this.props.alwaysVisibleCount.default;

    if (width >= 640 && isVariableDefinedNotNull(this.props.alwaysVisibleCount.sm)) {
      result = this.props.alwaysVisibleCount.sm;
    }

    if (width >= 768 && isVariableDefinedNotNull(this.props.alwaysVisibleCount.md)) {
      result = this.props.alwaysVisibleCount.md;
    }

    if (width >= 1024 && isVariableDefinedNotNull(this.props.alwaysVisibleCount.lg)) {
      result = this.props.alwaysVisibleCount.lg;
    }

    if (width >= 1280 && isVariableDefinedNotNull(this.props.alwaysVisibleCount.xl)) {
      result = this.props.alwaysVisibleCount.xl;
    }

    if (width >= 1536 && isVariableDefinedNotNull(this.props.alwaysVisibleCount['2xl'])) {
      result = this.props.alwaysVisibleCount['2xl'];
    }

    return result;
  }

  get visibleCount() {
    return this.props.visibleCount;
  }

  set visibleCount(value) {
    this.props.visibleCount = value;
  }

  get prevAlwaysVisibleCount() {
    return this.props.prevAlwaysVisibleCount;
  }

  set prevAlwaysVisibleCount(value) {
    this.props.prevAlwaysVisibleCount = value;
  }

  get isAllVisible() {
    if (this.type === 'items') {
      return this.visibleCount >= this.itemTargets.length;
    }

    return this.visibleCount >= this.textTarget.textContent.length;
  }

  get isRevealed() {
    return this.props.isRevealed;
  }

  set isRevealed(value) {
    this.props.isRevealed = value;
  }

  get type() {
    return this.props.type;
  }

  set type(value) {
    this.props.type = value;
  }

  get originalTextElement() {
    return this.props.originalTextElement;
  }

  set originalTextElement(value) {
    this.props.originalTextElement = value;
  }

  get truncatedTextElement() {
    return this.props.truncatedTextElement;
  }

  set truncatedTextElement(value) {
    this.props.truncatedTextElement = value;
  }
}
