import ApplicationController from 'modules/application_controller';
import scrollTop from 'plugins/utilities/scroll_top';

export default class extends ApplicationController {
  static get classes() {
    return ['bg'];
  }

  initialize() {
    this.props = {
      scrolled: false,
    };
  }

  connect() {
    this.handleScroll();
  }

  handleScroll() {
    const fromTop = scrollTop();

    if ((fromTop > 0 && this.scrolled) || (fromTop === 0 && !this.scrolled)) {
      return;
    }

    this.scrolled = fromTop > 0;

    if (this.scrolled) {
      this.element.classList.add(...this.bgClasses);
    } else {
      this.element.classList.remove(...this.bgClasses);
    }
  }

  get scrolled() {
    return this.props.scrolled;
  }

  set scrolled(value) {
    this.props.scrolled = value;
  }
}
